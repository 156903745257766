import {
  ContentContainer,
  InformacoesContainer,
  ItensContainer,
  PdfContainer,
  PedidosVisualizarContainer,
  StatusContainer,
} from "./styles";
import { ReactSVG } from "react-svg";
import NovoPedidoSvg from "../../assets/novo-pedido.svg";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../providers/Auth";
import { useState } from "react";
import Loading from "../Loading";
import api from "../../services/api";
import { useEffect } from "react";
import InformacoesSvg from "../../assets/informacoes.svg";
import VisualizarPdfSvg from "../../assets/visualizar-pdf.svg";
import ReprovadoSvg from "../../assets/reprovado.svg";
import AprovadoSvg from "../../assets/aprovado.svg";
import AjustarSvg from "../../assets/ajustar.svg";
import AguardandoSvg from "../../assets/aguardando.svg";
import ItensSvg from "../../assets/itens.svg";
import PdfViewer from "../PdfViewer";
import ModalReprovarPedido from "../Modals/ModalReprovarPedido";
import ModalAjustarPedido from "../Modals/ModalAjustarPedido";
import ModalAprovarPedido from "../Modals/ModalAprovarPedido";
import ModalAguardandoPedido from "../Modals/ModalAguardandoPedido";

const PedidosVisualizar = () => {
  const [pedido, setPedido] = useState("");
  const [levaAtual, setLevaAtual] = useState("");

  const [pedidoBebidaCategorias, setPedidoBebidaCategorias] = useState([]);
  const [pedidoBebidas, setPedidoBebidas] = useState([]);
  const [pedidoProdutoCategorias, setPedidoProdutoCategorias] = useState([]);
  const [pedidoProdutos, setPedidoProdutos] = useState([]);

  const { logout, user } = useAuth();

  const geoIdDoUsuarioLogado = user?.geo_id;
  const navigate = useNavigate();
  const { pedido_id } = useParams();

  const [modalReprovarPedido, setModalReprovarPedido] = useState(false);
  const [modalAjustarPedido, setModalAjustarPedido] = useState(false);
  const [modalAprovarPedido, setModalAprovarPedido] = useState(false);
  const [modalAguardandoPedido, setModalAguardandoPedido] = useState(false);

  const [categoriaBebidasLoaded, setCategoriaBebidasLoaded] = useState(false);
  const [pedidoBebidasLoaded, setPedidoBebidasLoaded] = useState(false);
  const [categoriaProdutoLoaded, setCategoriaProdutoLoaded] = useState(false);
  const [pedidoProdutosLoaded, setPedidoProdutosLoaded] = useState(false);
  const [levasLoaded, setLevasLoaded] = useState(false);
  const [pedidosLoaded, setPedidosLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      levasLoaded &&
      pedidosLoaded &&
      categoriaBebidasLoaded &&
      pedidoBebidasLoaded &&
      categoriaProdutoLoaded &&
      pedidoProdutosLoaded
    ) {
      setLoading(false);
    }
  }, [
    levasLoaded,
    pedidosLoaded,
    categoriaBebidasLoaded,
    pedidoBebidasLoaded,
    categoriaProdutoLoaded,
    pedidoProdutosLoaded,
  ]);

  const buscarLeva = () => {
    setLevasLoaded(false);
    api
      .get(`/levas`)
      .then((response) => {
        const [leva] = response.data.data.levas;
        if (!leva.data_encerramento) {
          setLevaAtual(leva);
        } else {
          setLevaAtual("");
        }
        setLevasLoaded(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout();
          navigate("/");
        }
        if (error.response?.status === 404) {
          navigate("/pedidos");
        }
      });
  };

  const buscarPedido = () => {
    setPedidosLoaded(false);
    api
      .get(`/pedidos/${pedido_id}`)
      .then((response) => {
        const pedido = response.data.data.pedido;
        setPedido(pedido);
        setPedidosLoaded(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout();
          navigate("/");
        }
        if (error.response?.status === 404) {
          navigate("/pedidos");
        }
      });
  };

  const buscarPedidoBebidaCategorias = () => {
    setCategoriaBebidasLoaded(false);
    api
      .get(`/pedido-bebida-categorias/${pedido.id}`)
      .then((response) => {
        const pedidoBebidaCategorias = response.data.data.categorias;
        setPedidoBebidaCategorias(pedidoBebidaCategorias);
        setCategoriaBebidasLoaded(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout();
          navigate("/");
        }
      });
  };

  const buscarPedidoBebidas = () => {
    setPedidoBebidasLoaded(false);
    api
      .get(`/pedido-bebidas/${pedido.id}`)
      .then((response) => {
        const pedidoBebidas = response.data.data.bebidas;
        setPedidoBebidas(pedidoBebidas);
        setPedidoBebidasLoaded(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout();
          navigate("/");
        }
      });
  };

  const buscarPedidoProdutoCategorias = () => {
    setCategoriaProdutoLoaded(false);
    api
      .get(`/pedido-produto-categorias/${pedido.id}`)
      .then((response) => {
        const pedidoProdutoCategorias = response.data.data.categorias;
        setPedidoProdutoCategorias(pedidoProdutoCategorias);
        setCategoriaProdutoLoaded(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout();
          navigate("/");
        }
      });
  };

  const buscarPedidoProdutos = () => {
    setPedidoProdutosLoaded(false);
    api
      .get(`/pedido-produtos/${pedido.id}`)
      .then((response) => {
        const pedidoProdutos = response.data.data.produtos;
        setPedidoProdutos(pedidoProdutos);
        setPedidoProdutosLoaded(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          logout();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    buscarLeva();
    buscarPedido();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pedido) {
      buscarPedidoBebidaCategorias();
      buscarPedidoBebidas();
      buscarPedidoProdutoCategorias();
      buscarPedidoProdutos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedido]);

  function capitalizeFirstLetter(str) {
    if(str){ 
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
}

  return (
    <PedidosVisualizarContainer>
      <div className="header">
        <button className='voltar' onClick={() => navigate(`/pedidos/?leva=${levaAtual.id}`)}>{"<"}</button>
        <ReactSVG src={NovoPedidoSvg} />{" "}
        <h1>#{pedido.id} - Visualizar pedido </h1>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <ContentContainer>
          <InformacoesContainer>
            <h2>
              <ReactSVG src={InformacoesSvg} /> Informações
            </h2>
            <div className="informacoes">
              <div className="item">
                <h3>Operação</h3>
                <p>{pedido.nome_operacao}</p>
              </div>
              <div className="item">
                <h3>PDV</h3>
                <p>{pedido.ponto_de_venda}</p>
              </div>
              <div className="item">
                <h3>Título</h3>
                <p>{pedido.titulo}</p>
              </div>
              <div className="item">
                <h3>Tipo</h3>
                <p>
                  {pedido.tipo === "verso" && "Frente e Verso C/ Capa"}
                  {pedido.tipo === "frenteVerso" && "Frente e Verso S/ Capa"}
                  {pedido.tipo === "paginaTodaSemCapa" && "Frente e Verso - Coluna Unica"}
                  {pedido.tipo === "misto" && "Frente e Verso Misto - S/ Capa x Coluna Unica"}
                  {pedido.tipo === "mistoPaginaToda" && "Frente e Verso Misto - Coluna Unica x S/ Capa"}
                </p>
              </div>
              <div className="item">
              { pedido.impresso === 1  &&( <div className='item'>
                        <h3>Quantidade</h3>
                        <p>
                            {pedido.quantidade} {pedido.quantidade > 1 ? 'unidades' : 'unidade'}
                        </p>
                    </div>)}
              </div>
              { pedido.qrcode === 1  &&(   <div className='item'>
                <h3>Quant. QR Code</h3>
                <p>
                  {pedido.quantidade_qrcode} {pedido.quantidade_qrcode > 1 ? 'unidades' : 'unidade'} 
                </p>
              </div>)}
              <div className="item">
                <h3>Marca</h3>
                <p>{pedido.template && capitalizeFirstLetter(pedido.template)}</p>
              </div>
              <div className="item">
                <h3>Cardápio</h3>
                <p>
                  {`${pedido.impresso ? "Impresso" : ""} ${
                    pedido.impresso && pedido.qrcode ? "e" : ""
                  } ${pedido.qrcode ? "QR Code" : ""}`}
                  {!pedido.impresso && !pedido.qrcode && "Digital"}
                </p>
              </div>
            </div>
          </InformacoesContainer>
          {pedido.link && !["CONCLUIDO", "REPROVADO"].includes(pedido.status) && (
            <PdfContainer>
              <h2>
                <ReactSVG src={VisualizarPdfSvg} /> Visualização de PDF
              </h2>
              <PdfViewer fileUrl={pedido.link} pedido={pedido} />

              {(["admin", "gv", "gc"].includes(user.permissao) &&
                  (pedido.status === "AGUARDANDO" || pedido.status === "REVISAR") || (["admin"].includes(user.permissao)) && pedido.status === "APROVADO"   && levaAtual) && (
                <StatusContainer>
                  <button
                    className="reprovar"
                    onClick={() => setModalReprovarPedido(true)}
                  >
                    <ReactSVG src={ReprovadoSvg} /> Reprovar
                  </button>
                  {(["admin", "gv", "gc"].includes(user.permissao) &&
                  (pedido.status === "AGUARDANDO" || (["admin"].includes(user.permissao) &&
                    pedido.status === "APROVADO"))) &&
                    levaAtual && (
                      <button
                        className="ajustar"
                        onClick={() => setModalAjustarPedido(true)}
                      >
                        <ReactSVG src={AjustarSvg} /> Ajustar
                      </button>
                    )}
                     {((["admin", "gv", "gc"].includes(user.permissao) && geoIdDoUsuarioLogado !== 3) ||
                    (["admin", "gc"].includes(user.permissao) && geoIdDoUsuarioLogado === 3)) && (
                  <button
                    className="aprovar"
                    onClick={() => setModalAprovarPedido(true)}
                  >
                    <ReactSVG src={AprovadoSvg} /> Aprovar
                  </button>
                  )}
          
              {((user.permissao === "admin" && pedido.status === "APROVADO") ||
                (user.permissao === "gv" &&
                  pedido.status === "APROVADO" &&
                  geoIdDoUsuarioLogado === 4) ||
                (user.permissao === "gc" && geoIdDoUsuarioLogado === 3 || geoIdDoUsuarioLogado === 1 )) && (
                
                  <button
                    className="aguardando"
                    onClick={() => setModalAguardandoPedido(true)}
                  >
                    <ReactSVG src={AguardandoSvg} /> Voltar para aguardando
                  </button>
                  
              )}
              </StatusContainer>
              )}
            </PdfContainer>
          )}
          {pedido.status === "CONCLUIDO" || pedido.status === "REPROVADO" && (
            <ItensContainer>
              <h2>
                <ReactSVG src={ItensSvg} /> Itens
              </h2>
              <div className="bebidas">
                <h3>Bebidas</h3>
                <div>
                  {pedidoBebidaCategorias.length ? (
                    pedidoBebidaCategorias.map((categoria) => (
                      <div key={categoria.id} className="item">
                        <h3>{categoria.nome}</h3>
                        {pedidoBebidas
                          .filter(
                            (bebida) =>
                              String(bebida.categoria_id) ===
                              String(categoria.id)
                          )
                          .map((bebida) => (
                            <p key={bebida.id}>
                              {bebida.nome} | {bebida.preco}
                            </p>
                          ))}
                      </div>
                    ))
                  ) : (
                    <h3>Nenhum item encontrado</h3>
                  )}
                </div>
              </div>
              <div className="produtos">
                <h3>Produtos</h3>
                <div>
                  {pedidoProdutoCategorias.length ? (
                    pedidoProdutoCategorias.map((categoria) => (
                      <div key={categoria.id} className="item">
                        <h3>{categoria.nome}</h3>
                        {pedidoProdutos
                          .filter(
                            (produto) =>
                              String(produto.categoria_id) ===
                              String(categoria.id)
                          )
                          .map((produto) => (
                            <p key={produto.id}>
                              {produto.nome} | {produto.preco}
                            </p>
                          ))}
                      </div>
                    ))
                  ) : (
                    <h3>Nenhum item encontrado</h3>
                  )}
                </div>
              </div>
            </ItensContainer>
          )}
        </ContentContainer>
      )}
      {modalReprovarPedido && (
        <ModalReprovarPedido
          setModalOpened={setModalReprovarPedido}
          pedido={pedido}
          buscarPedido={buscarPedido}
          levaAtual={levaAtual}
        />
      )}
      {modalAjustarPedido && (
        <ModalAjustarPedido
          setModalOpened={setModalAjustarPedido}
          pedido={pedido}
          buscarPedido={buscarPedido}
          levaAtual={levaAtual}
        />
      )}
      {modalAprovarPedido && (
        <ModalAprovarPedido
          setModalOpened={setModalAprovarPedido}
          pedido={pedido}
          buscarPedido={buscarPedido}
          levaAtual={levaAtual}
        />
      )}
      {modalAguardandoPedido && (
        <ModalAguardandoPedido
          setModalOpened={setModalAguardandoPedido}
          pedido={pedido}
          buscarPedido={buscarPedido}
          levaAtual={levaAtual}
        />
      )}
    </PedidosVisualizarContainer>
  );
};

export default PedidosVisualizar;
