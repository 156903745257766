import * as yup from 'yup';
import { useAuth } from '../../providers/Auth';

yup.addMethod(yup.string, 'produtoValido', function (pedido, message) {
    const { user } = useAuth();
    const geoIdDoUsuarioLogado = user?.geo_id;

    return this.test('produtoValido', message, function (value) {
        const { nome, volume } = this.parent;
        const produtoDigitadoComVolume = nome.toUpperCase() + ' ' + volume;
        
        if(geoIdDoUsuarioLogado === 1 && pedido.template !== 'brahma') {
            return true;
        }

        if (pedido.template === 'brahma' || pedido.template === 'brahma duplo malte') {
            let pontos = '...................................................................................';
            for (let i = 0; i < produtoDigitadoComVolume.length; i++) {
                if (['/', ' ', 'i', 'í', '1', '.', '-'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(1);
                };
                if (['(', ')', 'l', 'z', 'f', 'j' ,'x', 'y', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(2);
                };
                if (['c', 'ç', 'u', 'ú','ã', 'á', 'a', 'b', 't', 'r', 'q', 'd', 'e', 'é', 'ê', 'n', 'v', 'k', 'o', 'ó', 'ô', 'õ', 'g', 'h', 's', 'p',].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(3);
                };
                if (['%', 'm', 'w'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(4);
                };
            };
            if(pontos.length < 2) {
                return false;
            } else {
                return true;
            }
        } else if (pedido.template === 'spaten') {
            let pontos = '................................................................................';
            for (let i = 0; i < produtoDigitadoComVolume.length; i++) {
                if (['/','i', 'í', 'ì', '.', ',', '1'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(1);
                };
                if (['h', 'p', '(', ')','%','t', '3', '6', '7', '8', '9','b', 'c', 'ç', 'e', 'é', 'è', 'f', 'g', 'j', 'l', 'n', 'o', 'q', 'r', 's', 'u', 'v', 'x', 'y', 'z', 'ó', 'ô', '2', '5', '0', ' '].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(2);
                };
                if (['a', 'ã', 'á', 'd','4', 'k',].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(3);
                };
                if (['m', 'w'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(4);
                };
            };
            if(pontos.length < 2) {
                return false;
            } else {
                return true;
            }
        } else if (pedido.template === 'budweiser') {
            let pontos = '......................................................................';
            for (let i = 0; i < produtoDigitadoComVolume.length; i++) {
                if (['1', 'i', 'l', 'í', 'j', '.', ' ', '/', ',',].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(1);
                };
                if (['(', ')','3', '4', '6', '7', '8', '9', 'a', 'á', 'ã', 'b', 'ç', 'c', 'd', 'e', 'é', 'ê', 'è', 'f', 'g', 'h', 'n', 'k', 'o', 'p', 'q', 'r', 's', 't', 'v', 'x', 'u', 'y', 'z', 'ó', 'ô', '2', '5', '0'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(2);
                };
                if (['w','m','%'].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(3);
                };
                if ([].includes(produtoDigitadoComVolume[i].toLowerCase())) {
                    pontos = pontos.substring(4);
                }
            };
            if(pontos.length < 3) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        };
    });
});

const pedidoCategoriaProdutoSchema = (pedido) => {
    return yup.object().shape({
        nome: yup.string().required('Campo obrigatório').produtoValido(pedido, 'Reduza o nome do produto ou do volume.'),
        volume: yup.string().required('Campo obrigatório').produtoValido(pedido,'Reduza o nome do produto ou do volume.'),
        preco: yup.string().required('Campo obrigatório'),
    });
};

export default pedidoCategoriaProdutoSchema;